import { rgbToHex, styleVars } from '@/compositions/styleVariables'
import { getOrCreateLegendList } from '@/services/charts/chart'
import { useChartTooltip } from '@/compositions/chart/chartTooltip'
import type { ChartTooltipDataType } from '@/compositions/chart/chartTooltip'

const { initTooltip, removeTooltip, fillTooltip } = useChartTooltip()

const externalTooltipHandler = (context: Record<string, any>): undefined => {
  const { tooltip } = context
  const tooltipClass = 'line-chart__tooltip'
  initTooltip(context, tooltipClass)

  if (tooltip.opacity === 0) {
    removeTooltip()
    return
  }

  const title = tooltip.title[0] || ''
  const tooltipData: ChartTooltipDataType[] = []

  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b: any) => b.lines)

    bodyLines.forEach((body: any, i: number) => {
      const itemColor = tooltip.labelColors[i].backgroundColor
      const itemText = body[0].split(':')[0]
      const itemValue = body[0].split(':')[1]

      tooltipData.push({
        color: itemColor,
        text: itemText,
        value: itemValue,
      })
    })
  }

  fillTooltip(tooltipClass, title, tooltipData)
}

export const lineChartOptions = {
  maintainAspectRatio: false,
  clip: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: externalTooltipHandler,
    },
    htmlLegend: {
      legendClass: 'line-chart__legend',
    },
  },
  elements: {
    line: {
      tension: 0.05,
      borderWidth: 2,
    },
    point: {
      pointStyle: 'circle',
      pointBorderWidth: 0,
      pointRadius: 0,
      pointHoverBorderWidth: 4,
      pointHoverRadius: 4.5,
    },
  },
  scales: {
    yAxis: {
      position: 'right',
      beginAtZero: true,
      grid: {
        color: rgbToHex(styleVars.gray3),
        drawBorder: false,
        borderDash: [7, 3],
      },
      ticks: {
        color: rgbToHex(styleVars.gray7),
        padding: 12,
        align: 'end',
        maxTicksLimit: 6,
        font: {
          size: '12px',
          style: 'normal',
          weight: '400',
        },
      },
    },
    xAxis: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        color: rgbToHex(styleVars.gray7),
        maxTicksLimit: 20,
        padding: 4,
        font: {
          size: '12px',
          style: 'normal',
          weight: '400',
        },
      },
    },
  },
}

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart: Record<string, any>, args: Record<string, any>, options: Record<string, any>): void {
    const ul = getOrCreateLegendList(chart, options.legendClass)

    while (ul.firstChild) {
      ul.firstChild.remove()
    }

    const items = chart.options.plugins.legend.labels.generateLabels(chart)

    items.forEach((item: any) => {
      const li = document.createElement('li')
      const circle = document.createElement('div')
      circle.style.background = item.strokeStyle

      li.onclick = () => {
        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex))
        chart.update()
      }

      const text = document.createElement('span')
      text.appendChild(document.createTextNode(item.text))

      if (item.hidden) {
        circle.style.opacity = '.5'
        text.style.color = rgbToHex(styleVars.light)
      }

      li.appendChild(circle)
      li.appendChild(text)
      ul.appendChild(li)
    })
  },
}
