
import { defineComponent, ref } from 'vue'
import ReportGraphBlock from '@/components/shared/templates/reports/ReportGraphBlock.vue'
import LineChart from '@/components/shared/charts/LineChart.vue'

export default defineComponent({
  components: {
    ReportGraphBlock,
    LineChart,
  },
  props: {
    data: {
      type: Object,
    },
    timelineOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const timeline = ref(props.timelineOptions[0])

    return {
      timeline,
    }
  },
})
