import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d5d9a38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip-list" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "tooltip-list__position-content" }
const _hoisted_4 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tooltips, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.label,
        class: "tooltip-list__position"
      }, [
        (item.image)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: item.image,
              alt: "",
              class: "tooltip-list__position-image"
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createBlock(_component_tm_icon, {
              key: 1,
              name: "check_circle_outline",
              size: "xLarge",
              class: "success--text"
            })),
        _createElementVNode("div", _hoisted_3, _toDisplayString(item.label), 1),
        _createVNode(_component_tm_tooltip, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(item.text), 1)
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ]))
}