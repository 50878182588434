
import { defineComponent } from 'vue'
import FilterCheckbox from '@/components/shared/filters/FilterCheckbox.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'

export default defineComponent({
  name: 'FilterEmoji',
  components: {
    FilterCheckbox,
    TmEmoji,
  },
})
