
import { defineComponent } from 'vue'
import DropdownFilterCheckbox from '@/components/shared/filters/dropdown/DropdownFilterCheckbox.vue'
import FilterChannels from '@/components/shared/filters/FilterChannels.vue'

export default defineComponent({
  name: 'DropdownFilterChannels',
  components: {
    FilterChannels,
    DropdownFilterCheckbox,
  },
  props: {
    name: {
      type: String,
      default: 'Channel',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search channels',
    },
    hideSearch: {
      type: Boolean,
    },
  },
})
