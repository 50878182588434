import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center body-text-medium-14"
}
const _hoisted_2 = { class: "lh-20" }
const _hoisted_3 = {
  key: 0,
  class: "light--text body-text-regular-14 ml-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_flag = _resolveComponent("tm-flag")!
  const _component_filter_checkbox = _resolveComponent("filter-checkbox")!

  return (_openBlock(), _createBlock(_component_filter_checkbox, {
    "option-key": "name",
    "option-label": "name",
    "search-placeholder": "Search channels",
    searchable: !_ctx.hideSearch
  }, {
    option: _withCtx(({option}) => [
      (option.name)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (option.id)
              ? (_openBlock(), _createBlock(_component_tm_flag, {
                  key: 0,
                  country: option,
                  size: "small",
                  class: "mr-1"
                }, null, 8, ["country"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
              _createTextVNode(_toDisplayString(option.name) + " ", 1),
              (option.counter)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (" + _toDisplayString(option.counter) + ") ", 1))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["searchable"]))
}