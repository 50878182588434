
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import EmptyState from '@/components/shared/templates/EmptyState.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import TooltipList from '@/components/pages/reporting/TooltipList.vue'
import type { ReportsTooltips } from '@/definitions/reporting/types'
import type { SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmButton,
    EmptyState,
    WhiteBlock,
    TooltipList,
  },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    image: {
      type: String,
    },
    tooltips: {
      type: Array as PropType<ReportsTooltips[]>,
    },
    textSize: {
      type: String as SizeProp<'' | 'medium' | 'large'>,
      default: 'medium',
    },
  },
})
