import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_channels = _resolveComponent("filter-channels")!
  const _component_dropdown_filter_checkbox = _resolveComponent("dropdown-filter-checkbox")!

  return (_openBlock(), _createBlock(_component_dropdown_filter_checkbox, {
    name: _ctx.name,
    "option-key": "name",
    "option-label": "name",
    searchable: !_ctx.hideSearch,
    "dropdown-class": "filter-channels"
  }, {
    filter: _withCtx(({ close }) => [
      _createVNode(_component_filter_channels, _mergeProps(_ctx.$attrs, {
        class: "filter-statuses",
        "hide-search": _ctx.hideSearch,
        "search-placeholder": _ctx.searchPlaceholder,
        onOnApply: close,
        onOnCancel: close
      }), null, 16, ["hide-search", "search-placeholder", "onOnApply", "onOnCancel"])
    ]),
    _: 1
  }, 8, ["name", "searchable"]))
}