import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08ce6e12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "empty-state__left" }
const _hoisted_2 = { class: "empty-state__right" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["empty-state", {
      [`empty-state--text-${_ctx.kebabSize}`]: _ctx.textSize,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createElementVNode("div", {
        class: _normalizeClass(["empty-state__buttons", {
          'empty-state__buttons--small-gap': _ctx.smallGap
        }])
      }, [
        _renderSlot(_ctx.$slots, "buttons", {}, undefined, true)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require(`@/assets/images/empty-state/${_ctx.image}`),
        alt: ""
      }, null, 8, _hoisted_3)
    ])
  ], 2))
}