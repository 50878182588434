
import { defineComponent } from 'vue'
import FilterCheckbox from '@/components/shared/filters/FilterCheckbox.vue'
import TmFlag from '@/components/shared/TmFlag.vue'

export default defineComponent({
  name: 'FilterChannels',
  components: {
    FilterCheckbox,
    TmFlag,
  },
  props: {
    hideSearch: {
      type: Boolean,
    },
  },
})
