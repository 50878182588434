import type { ReportsTooltips } from '@/definitions/reporting/types'
import type { ReportCardType } from '@/definitions/shared/templates/types'

export const tooltipsTextMessages: ReportsTooltips[] = [
  {
    label: 'Toll-free number',
    text: 'Numbers that begin with a 8XX prefix and are most suitable for mass texting in the U.S. and Canada',
  },
  {
    label: 'Local number',
    text: 'Local 10-digit numbers with your area code for a personalized customer connection.',
  },
  {
    label: 'CPaaS number',
    text: 'You can connect your preferred CPaaS provider, like Twilio, Vonage, Sinch, or Bandwidth, and use these numbers for texting from Textmagic.',
  },
]

export const reportsOverviewTextMessagesStats: ReportCardType[] = [
  {
    title: 'Outbound SMS',
    tooltip: 'Total number of sent SMS messages for the selected period',
    counter: '126,3K',
    percent: {
      value: '+12.8%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '124,4K',
  },
  {
    title: 'Inbound SMS',
    tooltip: 'Total number of received SMS messages for the selected period.',
    counter: '1,677',
    percent: {
      value: '-9.2%',
      change: 'red',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '1,701',
  },
  {
    title: 'Delivery rate',
    tooltip: 'Percentage of successfully delivered messages for the selected period.',
    counter: '91.7%',
    percent: {
      value: '+14.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '77.5%',
  },
  {
    title: 'Response rate',
    tooltip: 'Percentage of sent messages that received a reply during the selected period.',
    counter: '19%',
    percent: {
      value: '+0.0%',
      change: 'blue',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '24',
  },
]
export const reportsOverviewTextMessagesStatsHalfEmpty: ReportCardType[] = reportsOverviewTextMessagesStats.map(stat => ({
  ...stat,
  counter: '0',
  previously: '0',
})).map(({ percent, ...stat }) => stat)

export const timelineTextMessagesOptions = [
  'Outbound SMS',
  'Inbound SMS',
  'Delivery rate',
  'Reply rate',
]

export const tooltipsCalls: ReportsTooltips[] = [
  {
    label: 'Toll-free number',
    text: 'Numbers that begin with a 8XX prefix and allow customers to call businesses without incurring charges',
  },
  {
    label: 'Local number',
    text: 'Local 10-digit numbers with your area code for a personalized customer connection.',
  },
  {
    label: 'CPaaS number',
    text: 'You can connect your Twilio numbers to set up call forwarding to your desired phone number.',
  },
]

export const reportsOverviewCallsStats: ReportCardType[] = [
  {
    title: 'Inbound calls',
    tooltip: 'Total number of inbound calls for the selected period.',
    counter: '145',
    percent: {
      value: '+12.8%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '129',
  },
  {
    title: 'Outbound calls',
    tooltip: 'Total number of outbound calls for the selected period.',
    counter: '188',
    percent: {
      value: '-9.2%',
      change: 'red',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '208',
  },
  {
    title: 'Forwarded calls',
    tooltip: 'Total number of forwarded calls for the selected period.',
    counter: '48',
    percent: {
      value: '-2.2%',
      change: 'red',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '53',
  },
  {
    title: 'Missed calls',
    tooltip: 'Total number of missed calls for the selected period.',
    counter: '14',
    percent: {
      value: '-14.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '17',
  },
  {
    title: 'Avg. call length',
    tooltip: 'Average call length duration of the calls for the selected period.',
    counter: '3m 18s',
    percent: {
      value: '+0.0%',
      change: 'blue',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '3m 18s',
  },
]
export const reportsOverviewCallsStatsHalfEmpty: ReportCardType[] = reportsOverviewCallsStats.map(stat => ({
  ...stat,
  counter: '0',
  previously: '0',
})).map(({ percent, ...stat }) => stat)

export const timelineCallsOptions = [
  'Inbound calls',
  'Outbound calls',
  'Missed calls',
  'Avg. call length',
]

export const reportsOverviewLiveChatsStats: ReportCardType[] = [
  {
    title: 'Live chats received',
    tooltip: 'Total number of received live chats for the selected period.',
    counter: '406',
    percent: {
      value: '+12.8%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '182',
  },
  {
    title: 'Live chats solved',
    tooltip: 'Total number of solved live chats for the selected period.',
    counter: '199',
    percent: {
      value: '-9.2%',
      change: 'red',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '221',
  },
  {
    title: 'Live chats missed',
    tooltip: 'Total number of missed live chats for the selected period.',
    counter: '16',
    percent: {
      value: '-14.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '18',
  },
  {
    title: 'Ratings received',
    tooltip: 'Total number of received live chat ratings messages for the selected period.',
    counter: '48',
    percent: {
      value: '+0.0%',
      change: 'blue',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '48',
  },
  {
    title: 'Satisfaction score',
    tooltip: 'Average satisfaction score rate for the received live chat ratings.',
    counter: '96%',
    percent: {
      value: '+3.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '93.5%',
  },
]
export const reportsOverviewLiveChatsStatsHalfEmpty: ReportCardType[] = reportsOverviewLiveChatsStats.map(stat => ({
  ...stat,
  counter: '0',
  previously: '0',
})).map(({ percent, ...stat }) => stat)

export const timelineLiveChatsOptions = [
  'Live chats received',
  'Live chats solved',
  'Live chats missed',
  'Ratings received',
  'Satisfaction rate',
]

export const tooltipsSocialChannels: ReportsTooltips[] = [
  {
    image: require('@/assets/images/chats/whatsapp.svg'),
    label: 'WhatsApp',
    text: 'Connect your WhatsApp Business Account and respond to customer-initiated WhatsApp conversations directly from Textmagic.',
  },
  {
    image: require('@/assets/images/chats/meta.svg'),
    label: 'Facebook Messenger',
    text: 'Connect your Facebook page to manage Messenger conversations directly in Textmagic.',
  },
  {
    image: require('@/assets/images/chats/instagram.svg'),
    label: 'Instagram',
    text: 'Connect your Instagram Business or Creator account to manage your Instagram conversations in Textmagic.',
  },
]

export const reportsOverviewSocialChannelsStats: ReportCardType[] = [
  {
    title: 'WhatsApp chats',
    tooltip: 'Total number of customer-initiated WhatsApp chats for the selected period.',
    counter: '126',
    percent: {
      value: '+12.8%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '118',
  },
  {
    title: 'Facebook Messenger chats',
    tooltip: 'Total number of Facebook Messenger chats for the selected period.',
    counter: '325',
    percent: {
      value: '-9.2%',
      change: 'red',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '363',
  },
  {
    title: 'Instagram chats',
    tooltip: 'Total number of  Instagram chats for the selected period.',
    counter: '56',
    percent: {
      value: '+14.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '41',
  },
]
export const reportsOverviewSocialChannelsStatsHalfEmpty: ReportCardType[] = reportsOverviewSocialChannelsStats.map(stat => ({
  ...stat,
  counter: '0',
  previously: '0',
})).map(({ percent, ...stat }) => stat)

export const timelineSocialChannelsOptions = [
  'WA chat number',
  'Facebook Messenger chats',
  'Instagram chat number',
]
