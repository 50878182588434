import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_line_chart = _resolveComponent("line-chart")!
  const _component_report_graph_block = _resolveComponent("report-graph-block")!

  return (_openBlock(), _createBlock(_component_report_graph_block, {
    title: "Timeline analysis",
    tooltip: "Analysis of the selected metric over time, comparing the current period with the previous period of equal length.",
    class: "pb-4"
  }, {
    "report-graph-top-right": _withCtx(() => [
      _createVNode(_component_tm_field, {
        modelValue: _ctx.timeline,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.timeline) = $event)),
        options: _ctx.timelineOptions,
        class: "w180px",
        type: "select",
        size: "small",
        clearable: false
      }, null, 8, ["modelValue", "options"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_line_chart, { "chart-data": _ctx.data }, null, 8, ["chart-data"])
    ]),
    _: 1
  }))
}