
import { defineComponent, computed } from 'vue'
import kebabCase from 'lodash/kebabCase'
import type { SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  props: {
    image: {
      type: String,
    },
    textSize: {
      type: String as SizeProp<'' | 'medium' | 'large'>,
      default: '',
    },
    smallGap: {
      type: Boolean,
    },
  },
  setup(props) {
    const kebabSize = computed(() => kebabCase(props.textSize))

    return {
      kebabSize,
    }
  },
})
