
import { defineComponent } from 'vue'
import FilterCheckbox from '@/components/shared/filters/FilterCheckbox.vue'
import TmChip from '@/components/shared/TmChip.vue'

export default defineComponent({
  name: 'FilterWidgets',
  components: {
    FilterCheckbox,
    TmChip,
  },
})
