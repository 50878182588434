
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { ReportsTooltips } from '@/definitions/reporting/types'

export default defineComponent({
  components: {
    TmTooltip,
  },
  props: {
    tooltips: {
      type: Array as PropType<ReportsTooltips[]>,
      default: () => [],
    },
  },
})
