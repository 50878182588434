import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center body-text-medium-14 lh-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_emoji = _resolveComponent("tm-emoji")!
  const _component_filter_checkbox = _resolveComponent("filter-checkbox")!

  return (_openBlock(), _createBlock(_component_filter_checkbox, {
    "option-key": "name",
    "option-label": "name",
    searchable: ""
  }, {
    option: _withCtx(({option}) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_emoji, {
          name: option.emoji,
          size: "large",
          class: "mr-2"
        }, null, 8, ["name"]),
        _createElementVNode("div", null, _toDisplayString(option.name), 1)
      ])
    ]),
    _: 1
  }))
}